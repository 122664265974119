import React, { useEffect, useRef } from "react";
import {
  StyledVideoCarousel,
  StyledVideoCarouselControl,
  StyledVideoCarouselControls,
  StyledVideoCarouselScrollbar,
  StyledVideoCarouselSlide,
  StyledVideoCarouselSlider,
  StyledVideoCarouselSwiper,
} from "./style";
import Swiper, { Navigation, Scrollbar } from "swiper";
import Icon from "../Icon/Icon";
import icons from "../Icon/icons";
import { Colors } from "../../commons/Theme";
import {
  StyledTitle,
  StyledParagraph,
  StyledBigText,
} from "../../commons/Typography";

Swiper.use([Navigation, Scrollbar]);

export default ({
  title, subtitle, bigText, videos, 
}) => {
  const sliderEl = useRef();
  const prevArrowEl = useRef();
  const nextArrowEl = useRef();
  const scrollBarEl = useRef();

  useEffect(() => {
    new Swiper(sliderEl.current, {
      allowTouchMove: "ontouchstart" in window,
      observer: true,
      observeParents: true,
      autoHeight: true,
      slidesPerView: 4,
      navigation: {
        prevEl: prevArrowEl.current,
        nextEl: nextArrowEl.current,
      },
      scrollbar: {
        el: scrollBarEl.current,
      },
    });
  }, [sliderEl]);

  return (
    <StyledVideoCarousel>
      <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
      <StyledParagraph dangerouslySetInnerHTML={{ __html: subtitle }} />
      <StyledBigText dangerouslySetInnerHTML={{ __html: bigText }} />
      <StyledVideoCarouselSwiper ref={sliderEl} className="swiper-container">
        <StyledVideoCarouselSlider className="swiper-wrapper">
          {videos.map((video, index) => (
            <StyledVideoCarouselSlide key={index} className="swiper-slide">
              {video.videoID}
            </StyledVideoCarouselSlide>
          ))}
        </StyledVideoCarouselSlider>
        <StyledVideoCarouselControls>
          <StyledVideoCarouselControl ref={prevArrowEl}>
            <Icon icon={icons.chevronLeft} color={Colors.white} />
          </StyledVideoCarouselControl>
          <StyledVideoCarouselControl ref={nextArrowEl} next>
            <Icon icon={icons.chevronRight} color={Colors.white} />
          </StyledVideoCarouselControl>
        </StyledVideoCarouselControls>
        <StyledVideoCarouselScrollbar
          className="swiper-scrollbar"
          ref={scrollBarEl}
        />
      </StyledVideoCarouselSwiper>
    </StyledVideoCarousel>
  );
};
